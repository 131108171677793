
.App {
  text-align: center;
  background-color: rgb(227, 232, 238);
  /* background-image: url(./calcBackground.png); */
  background-size: cover;
}

.header {
  border-radius: 5px;
  background-image: url(./calcBackground.png);
  background-size: cover;
  align-items: center;
}

.footer {
  /* background-image: url(./footer.jpeg);
  background-size: cover; */
  min-height: 50px;
}
.headerText {
  text-align: left;
  font-size: 70px;
  color: rgb(255, 255, 255);
  text-shadow: 3px 3px 3px rgb(2, 2, 2);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bolder;
  -webkit-text-stroke: 2px black;
  padding: 30px;
}

.introduction {
  background-color: rgb(91, 101, 112);
}

.App-link {
  color: rgb(112, 76, 182);
}

.calculator {
  justify-content: center;
}

.dashboard {
  border-radius: 5px;
  text-align: left;
  background-color: rgb(139, 146, 155);
  justify-content: center;
}

.calcForm {
  border-radius: 5px;
  text-align: left;
  background-color: rgb(139, 146, 155);
  justify-content: center;
}

.title {
  text-align: center;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 5px rgb(0, 0, 0);
  font-size: 60px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bolder;
}

.logo {
  text-align: center;
  color: rgb(255, 255, 255);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bolder;
}

.dashboardTitle {
  text-align: center;
  color: rgb(30, 31, 31);
  font-size: 60px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bolder;
}

.text {
  color: white;
  text-shadow: 2px 2px 2px black;
  font-size: 35px;
}
.text2 {
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 30px;
}

.blogText {
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 1px black;
  font-size: 20px;
}

.blogBody {
  margin-left: 100px;
  margin-right: 100px;
}

.blogVideo {
  padding: 15px;
  margin-left: 10%;
  border: 1px solid #a6a6a6;
  border-radius: 5px;
  background-image: linear-gradient(#f2f2f2, #e6e6e6);
  box-shadow: 5px 5px 5px grey;
  justify-content: center;
  width: 700px;
  height: 450px;
}

.introVideo {
  width: 700px;
  height: 450px;
}

.detailsImage {
  display: flex;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

@media only screen and (max-width: 1200Px) {
  .title {
    font-size: 30px;
  }
  .dashboardTitle {
    font-size: 45px;
  }
  .headerText {
    font-size: 40px;
  }
  .text {
    font-size: 20px;
  }
  .text2 {
    font-size: 18px;
  }
  .blogVideo {
    width: 700px;
    height: 395px;
  }
  .introVideo {
    width: 700px;
    height: 395px;
  }
}

@media only screen and (max-width: 1100Px) {
  .dashboardTitle {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1000Px) {
  .dashboardTitle {
    font-size: 35px;
  }
  .blogVideo {
    margin-left: 5%;
    width: 600px;
    height: 338px;
  }
  .introVideo {
    width: 600px;
    height: 338px;
  }
}

@media only screen and (max-width: 800Px) {
  .title {
    font-size: 20px;
  }
  .dashboardTitle {
    font-size: 30px;
  }
  .headerText {
    font-size: 30px;
  }
  .text {
    font-size: 15px;
  }
  .text2 {
    font-size: 15px;
  }
  .blogBody {
    margin-left: 50px;
    margin-right: 50px;
  }
  .blogText {
    font-size: 12px;
  }
  .blogVideo {
    margin-left: 5%;
    width: 550px;
    height: 310px;
  }
  .introVideo {
    width: 550px;
    height: 310px;
  }
  .animationBox {
    height: 90px;
    width: 90px;
    position: relative;
  }
  .orderButton {
    background-color: orangered;
    height: 90px;
    width: 90px;

    left: 0px;
    top: 0px;
    position: absolute;
    animation: rotate360 2s infinite;
  }
  .sideAds {
    justify-content: center;
  }
}
@media only screen and (max-width: 800Px) {
  .sideAds {
    display: none;
  }
  .introVideo {
    width: 425px;
    height: 240px;
  }
}
@media only screen and (max-width: 600Px) {
  .dashboardTitle {
    font-size: 20px;
  }
  .blogVideo {
    width: 425px;
    height: 240px;
  }
  .introVideo {
    width: 425px;
    height: 240px;
  }
  .sideAds {
    display: none;
  }
}
@media only screen and (max-width: 500Px) {
  .blogVideo {
    width: 375px;
    height: auto;
  }
  .introVideo {
    width: 375px;
    height: auto;
  }
  .sideAds {
    display: none;
  }
}
@media only screen and (max-width: 400Px) {
  .blogVideo {
    width: 325px;
    height: 211px;
  }
  .introVideo {
    width: 325px;
    height: 211px;
  }
  .sideAds {
    display: none;
  }
}
@media only screen and (max-width: 300Px) {
  .blogVideo {
    width: 250px;
    height: 141px;
  }
  .introVideo {
    width: 250px;
    height: 141px;
  }
  .sideAds {
    display: none;
  }
}
@keyframes rotate360 {
  0%	{transform: rotateY(360deg);}
  25% {left: 10px;}
  }

